import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  DateField,
  Create,
  Edit,
  SimpleForm,
  EditButton,
  TextInput,
  LongTextInput,
  ImageInput,
  required,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import { ListActions } from './ListActions';
import Title from './Title';
import PictureNote from 'components/PictureNote';
import Picture from 'components/Picture';
import UploadToolbar from 'components/UploadToolbar';

export const HomePromoIcon = createSvgIcon(
  <React.Fragment>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M1 5h2v14H1zm4 0h2v14H5zm17 0H10c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zM11 17l2.5-3.15L15.29 16l2.5-3.22L21 17H11z" />
  </React.Fragment>,
  'BurstMode',
);

export const HomePromoList = props => (
  <List actions={<ListActions />} title="Home Promo" {...props}>
    <Datagrid>
      <TextField source="id" label="No" />
      <ImageField source="promo_img_url" label="Image" />
      <TextField source="promo_name" label="Promo Name" />
      <TextField source="promo_action_url" label="URL Schema" />
      <EditButton />
    </Datagrid>
  </List>
);

export const HomePromoCreate = props => (
  <Create title={'New Home Promo'} {...props}>
    <SimpleForm redirect="list" toolbar={<UploadToolbar source={['promo_img_url']} />}>
      <TextInput source="promo_name" validate={required()} />
      <LongTextInput source="promo_action_url" validate={required()} />
      <ImageInput source="qpromo_img_url" label="New Image" accept="image/jpeg,image/png" validate={required()}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <PictureNote />
    </SimpleForm>
  </Create>
);

export const HomePromoEdit = withStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
})(({ classes, ...props }) => (
  <Edit title={<Title />} {...props}>
    <SimpleForm toolbar={<UploadToolbar source={['promo_img_url']} />}>
      <TextInput source="promo_name" validate={required()} />
      <LongTextInput source="promo_action_url" validate={required()} />
      <Picture field="promo_img_url" />
      <ImageInput source="qpromo_img_url" label="New Image" accept="image/jpeg,image/png">
        <ImageField source="src" title="title" />
      </ImageInput>
      <PictureNote />
      <DateField source="created_at" showTime={true} formClassName={classes.inlineBlock} />
      <DateField source="updated_at" showTime={true} formClassName={classes.inlineBlock} />
    </SimpleForm>
  </Edit>
));
