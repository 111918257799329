import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import { connect } from 'react-redux';
import { Title, showNotification, SimpleForm, FileInput, FileField, required } from 'react-admin';
import UploadFileToolbar from 'components/UploadFileToolbar';

class UploadIngredient extends Component {
  render() {
    return (
      <Card>
        <Title title="Upload Ingredients" />
        <SimpleForm toolbar={<UploadFileToolbar sourceKey="ingredients" redirectTo="/ingredients" />}>
          <FileInput
            source="file"
            label="File"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            validate={required()}
          >
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Card>
    );
  }
}

UploadIngredient.defaultProps = {
  undoable: true,
};

export default connect(
  undefined,
  {
    showNotification,
  },
)(UploadIngredient);
