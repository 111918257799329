import React from 'react';
import { CardActions, ListButton, RefreshButton } from 'react-admin';
import ReferenceAddButton from 'components/ReferenceAddButton';
import { IngredientItemIcon } from 'containers/IngredientItem';

const EditActions = ({ basePath, data, resource }) => {
  return (
    <CardActions>
      <RefreshButton />
      <ListButton basePath={basePath} />
      <ReferenceAddButton
        label="Add New Ingredient"
        source="qs"
        reference="ingredient_items"
        record={data}
        icon={<IngredientItemIcon />}
      />
    </CardActions>
  );
};

export default EditActions;
