import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  DateField,
  ReferenceField,
  EditButton,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
} from 'react-admin';
import { parse } from 'qs';
import { withStyles } from '@material-ui/core/styles';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import { ListFilter, ListActions } from './ListActions';
import Title from './Title';

export const IngredientItemIcon = createSvgIcon(
  <React.Fragment>
    <path fill="none" d="M-74 29h48v48h-48V29zM0 0h24v24H0V0zm0 0h24v24H0V0z" />
    <path d="M13 12h7v1.5h-7zm0-2.5h7V11h-7zm0 5h7V16h-7zM21 4H3c-1.1 0-2 .9-2 2v13c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 15h-9V6h9v13z" />
  </React.Fragment>,
  'ChromeReaderMode',
);

const CustomProductName = ({ record }) => (
  <span>
    <b>{record.sku}</b> - {record.item_name}
  </span>
);

const validateIngredientItem = values => {
  const limitNumber = 2147483647;
  const errors = {};
  if (values.max_qty > limitNumber) {
    errors.max_qty = ['Invalid input'];
  }
  return errors;
};

export const IngredientItemList = props => (
  <List filters={<ListFilter />} actions={<ListActions />} title="Ingredient Items" {...props}>
    <Datagrid>
      <TextField source="id" label="ID" />
      <ReferenceField reference="ingredients" source="ingredient_id" label="Ingredient Name" allowEmpty={true}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField reference="ingredients" source="ingredient_id" label="Ingredient Image" allowEmpty={true}>
        <ImageField source="img_url" />
      </ReferenceField>
      <ReferenceField reference="items" source="item_id" label="Item SKU" allowEmpty={true}>
        <TextField source="sku" />
      </ReferenceField>
      <TextField source="unit" label="Unit" />
      <TextField source="max_qty" label="Max Qty" />
      <EditButton />
    </Datagrid>
  </List>
);

export const IngredientItemCreate = props => {
  const query = parse(props.location.search, { ignoreQueryPrefix: true });
  let redirect = 'list',
    item_id = '';
  if (query.qs) {
    item_id = parseInt(query.qs, 10);
    redirect = `/items/${item_id}/2`;
  }
  return (
    <Create title={'New IngredientItem'} {...props}>
      <SimpleForm redirect={redirect} validate={validateIngredientItem}>
        <ReferenceInput source="ingredient_id" reference="ingredients" label="Ingredient Name" validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          defaultValue={item_id}
          source="item_id"
          reference="items"
          label="Product Name"
          validate={required()}
        >
          <SelectInput optionText={<CustomProductName />} />
        </ReferenceInput>
        <TextInput source="unit" label="Unit" validate={required()} />
        <NumberInput source="max_qty" label="Max Qty" validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export const IngredientItemEdit = withStyles({ inlineBlock: { display: 'inline-flex', marginRight: '1rem' } })(
  ({ classes, ...props }) => {
    const query = parse(props.location.search, { ignoreQueryPrefix: true });
    let redirect = 'list',
      item_id = '';
    if (query.qs) {
      item_id = parseInt(query.qs, 10);
      redirect = `/items/${item_id}/2`;
    }
    return (
      <Edit title={<Title />} {...props}>
        <SimpleForm redirect={redirect} validate={validateIngredientItem}>
          <ReferenceInput source="ingredient_id" reference="ingredients" validate={required()}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            defaultValue={item_id}
            source="item_id"
            reference="items"
            label="Product Name"
            validate={required()}
          >
            <SelectInput optionText={<CustomProductName />} />
          </ReferenceInput>
          <TextInput source="unit" label="Unit" validate={required()} />
          <NumberInput source="max_qty" label="Max Qty" validate={required()} />
          <DateField source="created_at" showTime={true} formClassName={classes.inlineBlock} />
          <DateField source="updated_at" showTime={true} formClassName={classes.inlineBlock} />
        </SimpleForm>
      </Edit>
    );
  },
);
