import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  ReferenceManyField,
  ReferenceField,
  DateField,
  EditButton,
  Create,
  Edit,
  SimpleForm,
  TabbedForm,
  FormTab,
  TextInput,
  ImageInput,
  SelectInput,
  LongTextInput,
  ReferenceInput,
  required,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { withStyles } from '@material-ui/core/styles';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import { ListFilter, ListActions } from './ListActions';
import EditActions from './EditActions';
import ReferenceEditButton from 'components/ReferenceEditButton';
import Title from './Title';
import Picture from 'components/Picture';
import PictureNote from 'components/PictureNote';
import UploadToolbar from 'components/UploadToolbar';

export const ItemIcon = createSvgIcon(
  <React.Fragment>
    <path d="M3 9h4V5H3v4zm0 5h4v-4H3v4zm5 0h4v-4H8v4zm5 0h4v-4h-4v4zM8 9h4V5H8v4zm5-4v4h4V5h-4zm5 9h4v-4h-4v4zM3 19h4v-4H3v4zm5 0h4v-4H8v4zm5 0h4v-4h-4v4zm5 0h4v-4h-4v4zm0-14v4h4V5h-4z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </React.Fragment>,
  'ViewComfy',
);

export const ItemList = props => (
  <List filters={<ListFilter />} actions={<ListActions />} title="Products" {...props}>
    <Datagrid>
      <TextField source="id" label="No" />
      <ImageField source="item_img_front_url" title="Image" label="Product Image" />
      <TextField source="sku" label="SKU" />
      <TextField source="item_name" label="Product Name" />
      <TextField source="collections.collection_name" label="Collection" />
      <TextField source="categories.category_name" label="Category" />
      <TextField source="item_types.item_type_name" label="Item Type" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ItemCreate = props => (
  <Create title={'New Item'} {...props}>
    <SimpleForm toolbar={<UploadToolbar source={['item_img_url', 'item_img_front_url', 'item_img_side_url']} />}>
      <TextInput source="sku" label="Item SKU" validate={required()} />
      <TextInput source="item_name" validate={required()} />
      <ReferenceInput source="category_id" reference="categories" validate={required()}>
        <SelectInput optionText="category_name" />
      </ReferenceInput>
      <ReferenceInput allowEmpty emptyValue={0} source="collection_id" reference="collections">
        <SelectInput optionText="collection_name" />
      </ReferenceInput>
      <ReferenceInput source="item_type_id" reference="item_types" validate={required()}>
        <SelectInput optionText="item_type_name" />
      </ReferenceInput>
      <LongTextInput resettable source="ribbon_text" />
      <LongTextInput source="snippet" />
      <RichTextInput source="item_details" />
      <ImageInput source="qitem_img_url" label="New Item Image" accept="image/jpeg,image/png">
        <ImageField source="src" title="title" />
      </ImageInput>
      <PictureNote />
      <ImageInput source="qitem_img_front_url" label="New Item Image Front" accept="image/jpeg,image/png">
        <ImageField source="src" title="title" />
      </ImageInput>
      <PictureNote />
      <Picture field="item_img_side_url" />
      <ImageInput source="qitem_img_side_url" label="New Item Image Side" accept="image/jpeg,image/png">
        <ImageField source="src" title="Title" />
      </ImageInput>
      <PictureNote />
      <TextInput source="capacity" />
      <TextInput source="weight_in_gram" />
      <TextInput source="dimension_in_mm" />
    </SimpleForm>
  </Create>
);

export const ItemEdit = withStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
  myTable: { width: '100% !important', tableLayout: 'auto' },
})(({ classes, ...props }) => {
  return (
    <Edit actions={<EditActions />} title={<Title />} {...props}>
      <TabbedForm toolbar={<UploadToolbar source={['item_img_url', 'item_img_front_url', 'item_img_side_url']} />}>
        <FormTab label="Details">
          <TextInput source="sku" label="Item SKU" validate={required()} />
          <TextInput source="item_name" validate={required()} />
          <ReferenceInput source="category_id" reference="categories" validate={required()}>
            <SelectInput optionText="category_name" />
          </ReferenceInput>
          <ReferenceInput allowEmpty emptyValue={0} source="collection_id" reference="collections">
            <SelectInput optionText="collection_name" />
          </ReferenceInput>
          <ReferenceInput source="item_type_id" reference="item_types" validate={required()}>
            <SelectInput optionText="item_type_name" />
          </ReferenceInput>
          <LongTextInput resettable source="ribbon_text" />
          <LongTextInput source="snippet" />
          <RichTextInput source="item_details" />
          <Picture field="item_img_url" />
          <ImageInput source="qitem_img_url" label="New Item Image" accept="image/jpeg,image/png">
            <ImageField source="src" title="Title" />
          </ImageInput>
          <PictureNote />
          <Picture field="item_img_front_url" />
          <ImageInput source="qitem_img_front_url" label="New Item Image Front" accept="image/jpeg,image/png">
            <ImageField source="src" title="Title" />
          </ImageInput>
          <PictureNote />
          <Picture field="item_img_side_url" />
          <ImageInput source="qitem_img_side_url" label="New Item Image Side" accept="image/jpeg,image/png">
            <ImageField source="src" title="Title" />
          </ImageInput>
          <PictureNote />
          <TextInput source="capacity" />
          <TextInput source="weight_in_gram" />
          <TextInput source="dimension_in_mm" />
          <DateField source="created_at" showTime={true} formClassName={classes.inlineBlock} />
          <DateField source="updated_at" showTime={true} formClassName={classes.inlineBlock} />
        </FormTab>
        <FormTab label="Prices">
          <ReferenceManyField perPage={1000} addLabel={false} reference="item_prices" target="item_id">
            <Datagrid classes={{ table: classes.myTable }}>
              <TextField source="reg_price" label="Reg Price" />
              <DateField source="reg_start_time" showTime={true} label="Reg Start Time" />
              <DateField source="reg_end_time" showTime={true} label="Reg End Time" />
              <TextField source="off_price" label="Off Price" />
              <DateField source="off_start_time" showTime={true} label="Off Start Time" />
              <DateField source="off_end_time" showTime={true} label="Off End Time" />
              <TextField source="nett_price" label="Nett Price" />
              <DateField source="nett_start_time" showTime={true} label="Nett Start Time" />
              <DateField source="nett_end_time" showTime={true} label="Nett End Time" />
              <ReferenceEditButton qskey="qs" reference="item_prices" qssource="items" target="item_id" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Ingredients">
          <ReferenceManyField perPage={1000} addLabel={false} reference="ingredient_items" target="item_id">
            <Datagrid classes={{ table: classes.myTable }}>
              <TextField source="id" label="ID" />
              <ReferenceField
                addLabel={false}
                reference="ingredients"
                source="ingredient_id"
                linkType={false}
                label="Ingredient Image"
                allowEmpty={true}
              >
                <ImageField source="img_url" title="name" />
              </ReferenceField>
              <ReferenceField
                addLabel={false}
                reference="ingredients"
                source="ingredient_id"
                linkType={false}
                label="Ingredient Name"
                allowEmpty={true}
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="unit" label="Unit" />
              <TextField source="max_qty" label="Max Qty" />
              <ReferenceEditButton qskey="qs" reference="ingredient_items" qssource="items" target="item_id" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
});
