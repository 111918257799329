import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Create,
  Edit,
  EditButton,
  SimpleForm,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import { ListFilter, ListActions } from './ListActions';
import Title from './Title';

export const CollectionsStorageAreaIcon = createSvgIcon(
  <React.Fragment>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z" />
  </React.Fragment>,
  'Store',
);

export const CollectionsStorageAreaList = props => (
  <List filters={<ListFilter />} actions={<ListActions />} title="Collections-Storage Areas" {...props}>
    <Datagrid>
      <TextField source="id" label="No" />
      <TextField source="collections.collection_name" label="Collection Name" />
      <ReferenceField reference="storage_areas" source="storage_area_id" label="Storage Area" allowEmpty={true}>
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export const CollectionsStorageAreaCreate = props => (
  <Create title={'New CollectionsStorageArea'} {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput label="Collection" source="collection_id" reference="collections">
        <SelectInput optionText="collection_name" />
      </ReferenceInput>
      <ReferenceInput label="Storage Area" source="storage_area_id" reference="storage_areas">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const CollectionsStorageAreaEdit = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm>
      <ReferenceInput label="Collection" source="collection_id" reference="collections">
        <SelectInput optionText="collection_name" />
      </ReferenceInput>
      <ReferenceInput label="Storage Area" source="storage_area_id" reference="storage_areas">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
