import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    display: 'block',
    width: '100%',
    float: 'left',
    color: '#818a91',
    fontSize: '14px',
    marginBottom: '20px',
  },
  title: {
    margin: '0',
  },
  content: {
    display: 'block',
    width: '100%',
    float: 'left',
  },
};

class PictureNote extends Component {
  render() {
    const { classes, h, w } = this.props;
    var dHeight = 1500,
      dWidth = 1500;

    console.log(h, w);

    if (h !== undefined) {
      dHeight = h;
    }
    if (w !== undefined) {
      dWidth = w;
    }
    console.log(dHeight, dWidth);
    return (
      <div className={classes.container}>
        <h5 className={classes.title}>Please Note: </h5>
        <small className={classes.content}>1. Image type has to be JPG/JPEG. </small>
        <small className={classes.content}>2. Image resolution Width X Height 1200px X 600px. </small>
        <small className={classes.content}>3. Image max size 500 Kb. Smaller size means faster loading.</small>
      </div>
    );
  }
}

export default withStyles(styles)(PictureNote);
