import React from 'react';

export default ({ record }) => {
  return (
    <span>
      Product Stacking
      {/* {record ? `: ${record.items.sku} - ${record.items.item_name}` : ''} */}
    </span>
  );
};
