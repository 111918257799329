import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  DateField,
  BooleanField,
  Create,
  Edit,
  SimpleForm,
  EditButton,
  TextInput,
  ImageInput,
  BooleanInput,
  required,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import { ListActions } from './ListActions';
import Title from './Title';
import PictureNote from 'components/PictureNote';
import Picture from 'components/Picture';
import UploadToolbar from 'components/UploadToolbar';

export const StorageAreaIcon = createSvgIcon(
  <React.Fragment>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M18 2.01L6 2c-1.1 0-2 .89-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.11-.9-1.99-2-1.99zM18 20H6v-9.02h12V20zm0-11H6V4h12v5zM8 5h2v3H8zm0 7h2v5H8z" />
  </React.Fragment>,
  'Kitchen',
);

export const StorageAreaList = props => (
  <List actions={<ListActions />} title="Storage Areas" {...props}>
    <Datagrid>
      <TextField source="id" label="No" />
      <TextField source="name" label="Name" />
      <ImageField source="before_img_url" label="Before Image" />
      <ImageField source="after_img_url" label="After Image" />
      <BooleanField source="is_deleted" label="Hidden" />
      <EditButton />
    </Datagrid>
  </List>
);

export const StorageAreaCreate = props => (
  <Create title={'New Home Promo'} {...props}>
    <SimpleForm redirect="list" toolbar={<UploadToolbar source={['before_img_url', 'after_img_url']} />}>
      <TextInput source="name" validate={required()} />
      <ImageInput source="qbefore_img_url" label="New Before Image" accept="image/jpeg,image/png" validate={required()}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput source="qafter_img_url" label="New After Image" accept="image/jpeg,image/png" validate={required()}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <PictureNote />
    </SimpleForm>
  </Create>
);

export const StorageAreaEdit = withStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
})(({ classes, ...props }) => (
  <Edit title={<Title />} {...props}>
    <SimpleForm toolbar={<UploadToolbar source={['before_img_url', 'after_img_url']} />}>
      <TextInput source="name" validate={required()} />
      <Picture field="before_img_url" />
      <ImageInput source="qbefore_img_url" label="New Before Image" accept="image/jpeg,image/png">
        <ImageField source="src" title="title" />
      </ImageInput>
      <PictureNote />
      <Picture field="after_img_url" />
      <ImageInput source="qafter_img_url" label="New After Image" accept="image/jpeg,image/png">
        <ImageField source="src" title="title" />
      </ImageInput>
      <PictureNote />
      <BooleanInput label="Hidden" source="is_deleted" />
      <DateField source="created_at" showTime={true} formClassName={classes.inlineBlock} />
      <DateField source="updated_at" showTime={true} formClassName={classes.inlineBlock} />
    </SimpleForm>
  </Edit>
));
