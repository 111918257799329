import React from 'react';

export default ({ record }) => {
  return (
    <span>
      Ingredient Item
      {record ? `: ${record.sku}` : ''}
    </span>
  );
};
