import React from 'react';

export default ({ record }) => {
  return (
    <span>
      Collections-Inspiration
      {record ? `: ${record.collections.collection_name} - ${record.title}` : ''}
    </span>
  );
};
