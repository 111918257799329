import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  Create,
  Edit,
  SimpleForm,
  EditButton,
  ReferenceInput,
  SelectInput,
  NumberInput,
  DateInput,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { parse } from 'qs';
import { withStyles } from '@material-ui/core/styles';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import { ListFilter, ListActions } from './ListActions';
import Title from './Title';

export const ItemPriceIcon = createSvgIcon(
  <React.Fragment>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M5 8h2v8H5zm7 0H9c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h3c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zm-1 6h-1v-4h1v4zm7-6h-3c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h3c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zm-1 6h-1v-4h1v4z" />
    <path fill="none" d="M4 6h16v12H4z" />
    <path d="M2 4v16h20V4H2zm2 14V6h16v12H4z" />
  </React.Fragment>,
  'Money',
);

const CustomProductName = ({ record }) => (
  <span>
    <b>{record.sku}</b> - {record.item_name}
  </span>
);

export const ItemPriceList = props => (
  <List filters={<ListFilter />} actions={<ListActions />} bulkActionButtons={false} title="Product Prices" {...props}>
    <Datagrid>
      <TextField source="id" label="No" />
      <ReferenceField reference="items" source="item_id" label="Product Name" allowEmpty={true}>
        <TextField source="item_name" />
      </ReferenceField>
      <TextField source="reg_price" label="Reg Price" />
      <DateField source="reg_start_time" label="Reg Start Time" />
      <DateField source="reg_end_time" label="Reg End Time" />
      <TextField source="off_price" label="Off Price" />
      <DateField source="off_start_time" label="Off Start Time" />
      <DateField source="off_end_time" label="Off End Time" />
      <TextField source="nett_price" label="Nett Price" />
      <DateField source="nett_start_time" label="Nett Start Time" />
      <DateField source="nett_end_time" label="Nett End Time" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ItemPriceCreate = props => {
  const query = parse(props.location.search, { ignoreQueryPrefix: true });
  let redirect = 'list',
    item_id = '';
  if (query.qs) {
    item_id = parseInt(query.qs, 10);
    redirect = `/items/${item_id}/1`;
  }

  const validatePriceForm = values => {
    const errors = {};
    if (values.reg_start_time != null && values.reg_end_time && values.reg_start_time > values.reg_end_time) {
      errors.reg_end_time = ['End time must be greater than start time '];
    }
    if (values.off_start_time != null && values.off_end_time && values.off_start_time > values.off_end_time) {
      errors.off_end_time = ['End time must be greater than start time '];
    }
    if (values.nett_start_time != null && values.nett_end_time && values.nett_start_time > values.nett_end_time) {
      errors.nett_end_time = ['End time must be greater than start time '];
    }
    return errors;
  };

  return (
    <Create title={'New ItemPrice'} {...props}>
      <SimpleForm redirect={redirect} validate={validatePriceForm}>
        <ReferenceInput defaultValue={item_id} source="item_id" reference="items" label="Product Name">
          <SelectInput optionText={<CustomProductName />} />
        </ReferenceInput>
        <NumberInput source="reg_price" />
        <DateInput source="reg_start_time" />
        <DateInput source="reg_end_time" />
        <NumberInput source="off_price" />
        <DateInput source="off_start_time" />
        <DateInput source="off_end_time" />
        <NumberInput source="nett_price" />
        <DateInput source="nett_start_time" />
        <DateInput source="nett_end_time" />
      </SimpleForm>
    </Create>
  );
};

const ItemPriceEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const ItemPriceEdit = withStyles({ inlineBlock: { display: 'inline-flex', marginRight: '1rem' } })(
  ({ classes, ...props }) => {
    const query = parse(props.location.search, { ignoreQueryPrefix: true });
    let redirect = 'list',
      item_id = '';
    if (query.qs) {
      item_id = parseInt(query.qs, 10);
      redirect = `/items/${item_id}/1`;
    }
    const validatePriceForm = values => {
      const errors = {};
      if (values.reg_start_time != null && values.reg_end_time && values.reg_start_time > values.reg_end_time) {
        errors.reg_end_time = ['End time must be greater than start time '];
      }
      if (values.off_start_time != null && values.off_end_time && values.off_start_time > values.off_end_time) {
        errors.off_end_time = ['End time must be greater than start time '];
      }
      if (values.nett_start_time != null && values.nett_end_time && values.nett_start_time > values.nett_end_time) {
        errors.nett_end_time = ['End time must be greater than start time '];
      }
      return errors;
    };
    return (
      <Edit title={<Title />} {...props}>
        <SimpleForm redirect={redirect} toolbar={<ItemPriceEditToolbar />} validate={validatePriceForm}>
          <NumberInput source="reg_price" />
          <DateInput source="reg_start_time" />
          <DateInput source="reg_end_time" />
          <NumberInput source="off_price" />
          <DateInput source="off_start_time" />
          <DateInput source="off_end_time" />
          <NumberInput source="nett_price" />
          <DateInput source="nett_start_time" />
          <DateInput source="nett_end_time" />
          <DateField source="created_at" showTime={true} formClassName={classes.inlineBlock} />
          <DateField source="updated_at" showTime={true} formClassName={classes.inlineBlock} />
        </SimpleForm>
      </Edit>
    );
  },
);
