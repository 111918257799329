import React from 'react';

export default ({ record }) => {
  return (
    <span>
      Cart Promotion
      {record ? `: ${record.product_name}` : ''}
    </span>
  );
};
