import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import LanguageIcon from '@material-ui/icons/Language';
import SettingsIcon from '@material-ui/icons/Settings';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { withRouter } from 'react-router-dom';
import { translate, DashboardMenuItem, MenuItemLink, Responsive } from 'react-admin';

import { FamilySizeIcon } from './containers/FamilySize';
import { IngredientIcon } from './containers/Ingredient';
import { IngredientItemIcon } from './containers/IngredientItem';

import { ItemIcon } from './containers/Item';
import { ItemPriceIcon } from './containers/ItemPrice';
import { ItemStackingIcon } from './containers/ItemStacking';
import { ItemTypeIcon } from './containers/ItemType';
import { CollectionIcon } from './containers/Collection';
import { CollectionsStorageAreaIcon } from './containers/CollectionsStorageArea';
import { StorageAreaIcon } from './containers/StorageArea';
import { CategoryIcon } from './containers/Category';

import { HomePromoIcon } from './containers/HomePromo';
import { CartPromotionIcon } from './containers/CartPromotion';
import { CollectionInspirationIcon } from './containers/CollectionInspiration';

import SubMenu from './SubMenu';

class Menu extends Component {
  state = {
    menuIngredients: false,
    menuItems: false,
    menuPromotions: false,
    menuCollections: false,
    menuConfigs: false,
  };

  static propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
  };

  handleToggle = menu => {
    this.setState(state => ({ [menu]: !state[menu] }));
  };

  render() {
    const { onMenuClick, open, logout, hasDashboard, dense } = this.props;
    return (
      <div>
        {' '}
        {hasDashboard && <DashboardMenuItem onClick={onMenuClick} />}
        <SubMenu
          key="menuConfigs"
          handleToggle={() => this.handleToggle('menuConfigs')}
          isOpen={this.state.menuConfigs}
          sidebarIsOpen={open}
          name="Settings"
          icon={<SettingsIcon />}
        >
          <MenuItemLink
            key="countryconfigs"
            to={`/countryconfigs`}
            primaryText="Country Config"
            leftIcon={<LanguageIcon />}
            onClick={onMenuClick}
            dense={dense}
          />
          <MenuItemLink
            to={`/family_sizes`}
            primaryText="Family Sizes"
            leftIcon={<FamilySizeIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/home_promos`}
            primaryText="Home Promo"
            leftIcon={<HomePromoIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/storage_areas`}
            primaryText="Storage Areas"
            leftIcon={<StorageAreaIcon />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <SubMenu
          key="menuCollections"
          handleToggle={() => this.handleToggle('menuCollections')}
          isOpen={this.state.menuCollections}
          sidebarIsOpen={open}
          name="Collections"
          icon={<CollectionIcon />}
        >
          <MenuItemLink
            to={`/collections`}
            primaryText="Collections"
            leftIcon={<CollectionIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/collections_storage_areas`}
            primaryText="Collections-Storages"
            leftIcon={<CollectionsStorageAreaIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/collection_inspirations`}
            primaryText="Collection-Inspiration"
            leftIcon={<CollectionInspirationIcon />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <SubMenu
          key="menuIngredients"
          handleToggle={() => this.handleToggle('menuIngredients')}
          isOpen={this.state.menuIngredients}
          sidebarIsOpen={open}
          name="Ingredients"
          icon={<IngredientIcon />}
        >
          <MenuItemLink
            to={`/ingredients`}
            primaryText="Ingredients"
            leftIcon={<IngredientIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/ingredient_items`}
            primaryText="Ingredient Items"
            leftIcon={<IngredientItemIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/upload-ingredients`}
            primaryText="Upload Ingredients"
            leftIcon={<CloudUploadIcon />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <SubMenu
          key="menuItems"
          handleToggle={() => this.handleToggle('menuItems')}
          isOpen={this.state.menuItems}
          sidebarIsOpen={open}
          name="Products"
          icon={<ItemIcon />}
        >
          <MenuItemLink to={`/items`} primaryText="Products" leftIcon={<ItemIcon />} onClick={onMenuClick} />
          <MenuItemLink to={`/categories`} primaryText="Categories" leftIcon={<CategoryIcon />} onClick={onMenuClick} />
          <MenuItemLink to={`/item_types`} primaryText="Types" leftIcon={<ItemTypeIcon />} onClick={onMenuClick} />
          <MenuItemLink to={`/item_prices`} primaryText="Prices" leftIcon={<ItemPriceIcon />} onClick={onMenuClick} />
          <MenuItemLink
            to={`/item_stackings`}
            primaryText="Stackings"
            leftIcon={<ItemStackingIcon />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <SubMenu
          key="menuPromotions"
          handleToggle={() => this.handleToggle('menuPromotions')}
          isOpen={this.state.menuPromotions}
          sidebarIsOpen={open}
          name="Promotions"
          icon={<CartPromotionIcon />}
          label="Promotions"
        >
          <MenuItemLink
            to={`/cart_promotions`}
            primaryText="Cart Promotions"
            leftIcon={<CartPromotionIcon />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <Responsive
          small={logout}
          medium={null} // Pass null to render nothing on larger devices
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme,
  locale: state.i18n.locale,
});

const enhance = compose(
  withRouter,
  translate,
  connect(
    mapStateToProps,
    {},
  ),
  translate,
);

export default enhance(Menu);
