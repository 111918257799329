import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  root: { display: 'inline-block', marginTop: '1em', zIndex: 2 },
  content: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:last-child': { padding: 0 },
  },
  img: {
    width: 'initial',
    minWidth: 'initial',
    maxWidth: '42em',
    maxHeight: '15em',
  },
};

class Picture extends Component {
  renderContainer() {
    const { classes, record, field } = this.props;
    return (
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <img src={record[field]} alt="" className={classes.img} />
        </CardContent>
      </Card>
    );
  }
  render() {
    return this.renderContainer();
  }
}

export default withStyles(styles)(Picture);
