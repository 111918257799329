import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  DateField,
  EditButton,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ImageInput,
  required,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import { ListFilter, ListActions } from './ListActions';
import Title from './Title';
import Picture from 'components/Picture';
import PictureNote from 'components/PictureNote';
import UploadToolbar from 'components/UploadToolbar';

export const IngredientIcon = createSvgIcon(
  <React.Fragment>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M10 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM6 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12-8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-4 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm4-4c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-4-4c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-4-4c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
  </React.Fragment>,
  'Grain',
);

const validateIngredient = values => {
  const errors = {};
  if (values.slider_min > values.slider_max) {
    errors.slider_min = ['Invalid input'];
    errors.slider_max = ['Invalid input'];
  }
  if (values.slider_steps > values.slider_max) {
    errors.slider_steps = ['Invalid input'];
  }
  return errors;
};

export const IngredientList = props => (
  <List filters={<ListFilter />} actions={<ListActions />} title="Ingredients" {...props}>
    <Datagrid>
      <TextField source="name" label="Name" />
      <ImageField source="img_url" title="Image" label="Image" />
      <TextField source="grouping" label="Grouping" />
      <TextField source="slider_unit" label="Unit" />
      <TextField source="slider_min" label="Min" />
      <TextField source="slider_max" label="Max" />
      <TextField source="slider_steps" label="Steps" />
      <EditButton />
    </Datagrid>
  </List>
);

export const IngredientCreate = props => (
  <Create title={'New Ingredient'} {...props}>
    <SimpleForm validate={validateIngredient} redirect="list" toolbar={<UploadToolbar source={['img_url']} />}>
      <TextInput source="name" validate={required()} />
      <TextInput source="grouping" validate={required()} />
      <TextInput source="slider_unit" validate={required()} />
      <NumberInput source="slider_min" validate={required()} />
      <NumberInput source="slider_max" validate={required()} />
      <NumberInput source="slider_steps" validate={required()} />
      <ImageInput source="qimg_url" label="New Image" accept="image/jpeg,image/png" validate={required()}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <PictureNote />
    </SimpleForm>
  </Create>
);

export const IngredientEdit = withStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
})(({ classes, ...props }) => {
  // let img_url = { img_url: null };
  return (
    <Edit title={<Title />} {...props}>
      <SimpleForm validate={validateIngredient} toolbar={<UploadToolbar source={['img_url']} />}>
        <TextInput source="name" validate={required()} />
        <TextInput source="grouping" validate={required()} />
        <TextInput source="slider_unit" validate={required()} />
        <NumberInput source="slider_min" validate={required()} />
        <NumberInput source="slider_max" validate={required()} />
        <NumberInput source="slider_steps" validate={required()} />
        <Picture field="img_url" />
        <ImageInput source="qimg_url" label="New Image" accept="image/jpeg,image/png">
          <ImageField source="src" title="title" />
        </ImageInput>
        <PictureNote />
        <DateField source="created_at" showTime={true} formClassName={classes.inlineBlock} />
        <DateField source="updated_at" showTime={true} formClassName={classes.inlineBlock} />
      </SimpleForm>
    </Edit>
  );
});
