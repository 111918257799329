import React from 'react';
import PropTypes from 'prop-types';
import inflection from 'inflection';
import { Link, Button } from 'react-admin';

const ReferenceAddButton = ({ source, reference, record, label, icon }) => {
  return record ? (
    <Button
      component={Link}
      to={{
        pathname: `/${reference}/create`,
        search: `?${source}=${record.id}`,
      }}
      label={label || `Add ${inflection.singularize(inflection.humanize(reference))}`}
    >
      {icon && icon}
    </Button>
  ) : null;
};

ReferenceAddButton.propTypes = {
  source: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  record: PropTypes.object,
};

export default ReferenceAddButton;
