import React from 'react';
import { List, Datagrid, TextField, Create, Edit, SimpleForm } from 'react-admin';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import { ListFilter, ListActions } from './ListActions';
import Title from './Title';

export const CategoryIcon = createSvgIcon(
  <React.Fragment>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M3 19h6v-7H3v7zm7 0h12v-7H10v7zM3 5v6h19V5H3z" />
  </React.Fragment>,
  'ViewCompact',
);

export const CategoryList = props => (
  <List
    filters={<ListFilter />}
    actions={<ListActions />}
    title="Product Categories"
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField source="id" label="No" />
      <TextField source="category_name" label="Name" />
    </Datagrid>
  </List>
);

export const CategoryCreate = props => (
  <Create title={'New Category'} {...props}>
    <SimpleForm />
  </Create>
);

export const CategoryEdit = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm />
  </Edit>
);
