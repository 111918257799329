import React from 'react';
import { Admin, Resource, Login, LoginForm } from 'react-admin';
import './App.css';
import initDataProvider from './utils/dataProvider';
import initAuthProvider from './utils/authProvider';
import { ItemIcon, ItemList, ItemCreate, ItemEdit } from './containers/Item';
import { ItemTypeIcon, ItemTypeList, ItemTypeCreate, ItemTypeEdit } from './containers/ItemType';
import { ItemPriceIcon, ItemPriceList, ItemPriceCreate, ItemPriceEdit } from './containers/ItemPrice';
import { ItemStackingIcon, ItemStackingList, ItemStackingCreate, ItemStackingEdit } from './containers/ItemStacking';
import { CategoryIcon, CategoryList, CategoryCreate, CategoryEdit } from './containers/Category';
import {
  CartPromotionIcon,
  CartPromotionList,
  CartPromotionCreate,
  CartPromotionEdit,
} from './containers/CartPromotion';
import { CollectionIcon, CollectionList, CollectionCreate, CollectionEdit } from './containers/Collection';
import {
  CollectionsStorageAreaIcon,
  CollectionsStorageAreaList,
  CollectionsStorageAreaCreate,
  CollectionsStorageAreaEdit,
} from './containers/CollectionsStorageArea';
import { FamilySizeIcon, FamilySizeList, FamilySizeCreate, FamilySizeEdit } from './containers/FamilySize';
import { IngredientIcon, IngredientList, IngredientCreate, IngredientEdit } from './containers/Ingredient';
import {
  IngredientItemIcon,
  IngredientItemList,
  IngredientItemCreate,
  IngredientItemEdit,
} from './containers/IngredientItem';
import { StorageAreaIcon, StorageAreaList, StorageAreaCreate, StorageAreaEdit } from './containers/StorageArea';
import { HomePromoIcon, HomePromoList, HomePromoCreate, HomePromoEdit } from './containers/HomePromo';
import {
  CollectionInspirationIcon,
  CollectionInspirationList,
  CollectionInspirationCreate,
  CollectionInspirationEdit,
} from './containers/CollectionInspiration';

import Menu from './Menu';
import customRoutes from './customRoutes';

const dataProvider = initDataProvider();
const authProvider = initAuthProvider();

// const MyLayout = Layout;
// MyLayout.defaultProps.menu = MyMenu;

const MyLogin = Login;
MyLogin.defaultProps = {
  backgroundImage: 'https://source.unsplash.com/random/1600x900?food',
  loginForm: <LoginForm redirectTo="/countryconfigs" />,
};

const App = () => (
  <Admin
    authProvider={authProvider}
    loginPage={MyLogin}
    dataProvider={dataProvider}
    customRoutes={customRoutes}
    menu={Menu}
  >
    <Resource
      icon={FamilySizeIcon}
      name="family_sizes"
      options={{ label: 'Family Sizes' }}
      list={FamilySizeList}
      create={FamilySizeCreate}
      edit={FamilySizeEdit}
    />
    <Resource
      icon={HomePromoIcon}
      name="home_promos"
      options={{ label: 'Home Promo' }}
      list={HomePromoList}
      create={HomePromoCreate}
      edit={HomePromoEdit}
    />
    <Resource
      icon={IngredientIcon}
      name="ingredients"
      options={{ label: 'Ingredients' }}
      list={IngredientList}
      create={IngredientCreate}
      edit={IngredientEdit}
    />
    <Resource
      icon={IngredientItemIcon}
      name="ingredient_items"
      options={{ label: 'Ingredient Items' }}
      list={IngredientItemList}
      create={IngredientItemCreate}
      edit={IngredientItemEdit}
    />
    <Resource
      icon={CollectionIcon}
      name="collections"
      options={{ label: 'Product Collections' }}
      list={CollectionList}
      create={CollectionCreate}
      edit={CollectionEdit}
    />

    <Resource
      icon={CategoryIcon}
      name="categories"
      options={{ label: 'Product Categories' }}
      list={CategoryList}
      create={CategoryCreate}
      edit={CategoryEdit}
    />
    <Resource
      icon={ItemTypeIcon}
      name="item_types"
      options={{ label: 'Product Types' }}
      list={ItemTypeList}
      create={ItemTypeCreate}
      edit={ItemTypeEdit}
    />
    <Resource
      icon={ItemPriceIcon}
      name="item_prices"
      options={{ label: 'Product Prices' }}
      list={ItemPriceList}
      create={ItemPriceCreate}
      edit={ItemPriceEdit}
    />
    <Resource
      icon={ItemStackingIcon}
      name="item_stackings"
      options={{ label: 'Product Stackings' }}
      list={ItemStackingList}
      create={ItemStackingCreate}
      edit={ItemStackingEdit}
    />
    <Resource
      icon={ItemIcon}
      name="items"
      options={{ label: 'Products' }}
      list={ItemList}
      create={ItemCreate}
      edit={ItemEdit}
    />
    <Resource
      icon={CartPromotionIcon}
      name="cart_promotions"
      options={{ label: 'Cart Promotions' }}
      list={CartPromotionList}
      create={CartPromotionCreate}
      edit={CartPromotionEdit}
    />
    <Resource
      icon={CollectionsStorageAreaIcon}
      name="collections_storage_areas"
      options={{ label: 'Collections Storage Areas' }}
      list={CollectionsStorageAreaList}
      create={CollectionsStorageAreaCreate}
      edit={CollectionsStorageAreaEdit}
    />
    <Resource
      icon={StorageAreaIcon}
      name="storage_areas"
      options={{ label: 'Storage Areas' }}
      list={StorageAreaList}
      create={StorageAreaCreate}
      edit={StorageAreaEdit}
    />
    <Resource
      icon={CollectionInspirationIcon}
      name="collection_inspirations"
      options={{ label: 'Collection Inspiration' }}
      list={CollectionInspirationList}
      create={CollectionInspirationCreate}
      edit={CollectionInspirationEdit}
    />
  </Admin>
);

export default App;
