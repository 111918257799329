import React, { Component } from 'react';
import { CardActions, ExportButton, Filter, TextInput, RefreshButton, CreateButton } from 'react-admin';
import { refreshView as refreshViewAction } from 'ra-core';
import { connect } from 'react-redux';

export const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="f" alwaysOn />
    <TextInput label="Name" source="name" />
  </Filter>
);

class MyListActions extends Component {
  render() {
    const { resource, currentSort, filterValues, exporter, basePath } = this.props;
    return (
      <CardActions>
        <ExportButton resource={resource} sort={currentSort} filter={filterValues} exporter={exporter} />
        <RefreshButton />
        <CreateButton basePath={basePath} />
      </CardActions>
    );
  }
  componentDidMount() {
    const { refreshInterval, refreshView } = this.props;
    if (refreshInterval) {
      this.interval = setInterval(() => {
        refreshView();
      }, refreshInterval);
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
}

export const ListActions = connect(
  null,
  { refreshView: refreshViewAction },
)(MyListActions);
