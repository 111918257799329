import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  Create,
  Edit,
  EditButton,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  ImageInput,
} from 'react-admin';
import { ListFilter, ListActions } from './ListActions';
import Title from './Title';
import Picture from 'components/Picture';
import PictureNote from 'components/PictureNote';
import UploadToolbar from 'components/UploadToolbar';
import Icon from '@material-ui/icons/StarBorder';
import RichTextInput from 'ra-input-rich-text';

// export const CollectionInspirationIcon = createSvgIcon(
//   <React.Fragment>
//     <path fill="none" d="M0 0h24v24H0z" />
//     <path d="M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z" />
//   </React.Fragment>,
//   'Store',
// );

export const CollectionInspirationIcon = Icon;

export const CollectionInspirationList = props => (
  <List filters={<ListFilter />} actions={<ListActions />} title="Collections-Storage Areas" {...props}>
    <Datagrid>
      <TextField source="id" label="No" />
      <TextField source="collections.collection_name" label="Collection" />
      <TextField source="title" />
      <ImageField source="img_url" />
      <EditButton />
    </Datagrid>
  </List>
);

export const CollectionInspirationCreate = props => (
  <Create title={'New CollectionInspiration'} {...props}>
    <SimpleForm redirect="list" toolbar={<UploadToolbar source={['img_url']} />}>
      <ReferenceInput label="Collection" source="collection_id" reference="collections">
        <SelectInput optionText="collection_name" />
      </ReferenceInput>
      <TextInput source="title" />
      <Picture field="img_url" />
      <ImageInput source="qimg_url" label="New Image" accept="image/jpeg,image/png">
        <ImageField source="src" title="title" />
      </ImageInput>
      <PictureNote h="1200" w="600" />
      <RichTextInput source="description_html" />
    </SimpleForm>
  </Create>
);

export const CollectionInspirationEdit = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm toolbar={<UploadToolbar source={['img_url']} />}>
      <ReferenceInput label="Collection" source="collection_id" reference="collections">
        <SelectInput optionText="collection_name" />
      </ReferenceInput>
      <TextInput source="title" />
      <Picture field="img_url" />
      <ImageInput source="qimg_url" label="New Image" accept="image/jpeg,image/png">
        <ImageField source="src" title="title" />
      </ImageInput>
      <PictureNote h="1200" w="600" />
      <RichTextInput source="description_html" />
    </SimpleForm>
  </Edit>
);
