import React from 'react';
import { List, Datagrid, TextField, ImageField, Create, Edit, SimpleForm } from 'react-admin';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import { ListFilter, ListActions } from './ListActions';
import Title from './Title';

export const CollectionIcon = createSvgIcon(
  <React.Fragment>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2.03 2.71L16 11l4 5H8l3-4zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z" />
  </React.Fragment>,
  'Collections',
);

export const CollectionList = props => (
  <List
    filters={<ListFilter />}
    actions={<ListActions />}
    title="Product Collections"
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField source="id" label="No" />
      <ImageField source="img_url" label="Image" />
      <TextField source="collection_name" label="Collection Name" />
      <TextField source="color" label="Color" />
    </Datagrid>
  </List>
);

export const CollectionCreate = props => (
  <Create title={'New Collection'} {...props}>
    <SimpleForm />
  </Create>
);

export const CollectionEdit = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm />
  </Edit>
);
