import React from 'react';
import { DeleteButton } from 'react-admin';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import MuiToolbar from '@material-ui/core/Toolbar';
import compose from 'recompose/compose';
import classnames from 'classnames';
import UploadSaveButton from './UploadSaveButton';

const styles = theme => ({
  toolbar: {
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
  },
  desktopToolbar: {
    marginTop: theme.spacing.unit * 2,
  },
  mobileToolbar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '16px',
    width: '100%',
    boxSizing: 'border-box',
    flexShrink: 0,
    zIndex: 2,
  },
  defaultToolbar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  spacer: {
    [theme.breakpoints.down('xs')]: {
      height: '5em',
    },
  },
});

const UploadToolbar = ({
  source,
  resource,
  record,
  invalid,
  pristine,
  redirect,
  saving,
  submitOnEnter,
  handleSubmit,
  handleSubmitWithRedirect,
  basePath,
  classes,
  className,
  width,
  ...rest
}) => (
  <MuiToolbar
    className={classnames(
      classes.toolbar,
      {
        [classes.mobileToolbar]: width === 'xs',
        [classes.desktopToolbar]: width !== 'xs',
      },
      className,
    )}
    role="toolbar"
    {...rest}
  >
    <div className={classes.defaultToolbar}>
      <UploadSaveButton
        basePath={basePath}
        source={source}
        resource={resource}
        record={record}
        invalid={invalid}
        pristine={pristine}
        redirect={redirect}
        saving={saving}
        submitOnEnter={submitOnEnter}
        handleSubmit={handleSubmit}
      />
      {record && typeof record.id !== 'undefined' && (
        <DeleteButton basePath={basePath} record={record} resource={resource} />
      )}
    </div>
  </MuiToolbar>
);

const enhance = compose(
  withWidth(),
  withStyles(styles),
);
export default enhance(UploadToolbar);
