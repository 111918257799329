import React from 'react';

export default ({ record }) => {
  return (
    <span>
      Home Promo
      {record ? `: ${record.promo_name}` : ''}
    </span>
  );
};
