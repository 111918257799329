import React from 'react';
import { List, Datagrid, TextField, ImageField, Create, Edit, SimpleForm } from 'react-admin';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import { ListActions } from './ListActions';
import Title from './Title';

export const FamilySizeIcon = createSvgIcon(
  <React.Fragment>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M16.5 12c1.38 0 2.49-1.12 2.49-2.5S17.88 7 16.5 7C15.12 7 14 8.12 14 9.5s1.12 2.5 2.5 2.5zM9 11c1.66 0 2.99-1.34 2.99-3S10.66 5 9 5C7.34 5 6 6.34 6 8s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13z" />
  </React.Fragment>,
  'SupervisorAccount',
);

export const FamilySizeList = props => (
  <List actions={<ListActions />} title="Family Sizes" bulkActionButtons={false} {...props}>
    <Datagrid>
      <TextField source="id" label="No" />
      <ImageField source="img_url" label="Image" />
      <TextField source="name" label="Name" />
      <TextField source="no_of_person" label="No of Person" />
    </Datagrid>
  </List>
);

export const FamilySizeCreate = props => (
  <Create title={'New Family Size'} {...props}>
    <SimpleForm />
  </Create>
);

export const FamilySizeEdit = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm />
  </Edit>
);
