import React from 'react';

export default ({ record }) => {
  return (
    <span>
      Collections-Storage Areas
      {record ? `: ${record.collections.collection_name} - ${record.storage_areas.name}` : ''}
    </span>
  );
};
