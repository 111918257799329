import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import { connect } from 'react-redux';
import {
  Title,
  TabbedForm,
  FormTab,
  LongTextInput,
  DisabledInput,
  showNotification,
  Toolbar,
  SaveButton,
} from 'react-admin';
import initDataProvider from 'utils/dataProvider';
import RichTextInput from 'ra-input-rich-text';
// import MyToolbar from './MyToolbar';
const dataProvider = initDataProvider();

class CountryConfig extends Component {
  state = {
    data: {
      country_name: 'Jin',
    },
  };

  componentDidMount() {
    // let data = {};
    let params = { id: 1 };
    dataProvider('GET_ONE', 'countrysettings', params).then(res => {
      this.setState({
        data: res.data,
      });
    });
  }

  handleSubmit = values => {
    const { showNotification } = this.props;
    dataProvider('UPDATE_COUNTRYSETTING', 'countrysettings', values)
      .then(res => {
        if ((res.data.id = 1)) {
          showNotification('Element updated', 'info');
        }
      })
      .catch(error => {
        console.log('err', error.message);
        showNotification(error.message, 'warning');
      });
  };

  render() {
    const { data } = this.state;

    const MyToolbar = props => (
      <Toolbar {...props}>
        <SaveButton label="Save" />
      </Toolbar>
    );

    return (
      <Card>
        <Title title="Country Config" />
        <TabbedForm toolbar={<MyToolbar />} record={data} save={this.handleSubmit}>
          <FormTab label="General">
            <DisabledInput source="country_name" label="Country Name" />
            <DisabledInput source="country_code" label="Country Code" />
            <DisabledInput source="currency_sign" label="Currency Sign" />
          </FormTab>
          <FormTab label="Catalog & Brochure">
            <LongTextInput source="main_menu_catalog_url" label="Catalog URL" />
            <LongTextInput source="main_menu_consumer_brochure_url" label="Consumer Brochure URL" />
            <LongTextInput source="main_menu_sales_force_flyer_url" label="Sales Force Flyer Brochure URL" />
          </FormTab>
          <FormTab label="Others">
            <LongTextInput source="forgot_password_url" label="Forgot Password URL" />
            <LongTextInput source="change_password_url" label="Change Password URL" />
            <LongTextInput source="tnc_url" label="T&C URL" />
            <LongTextInput source="privacy_url" label="Privacy URL" />
            <LongTextInput source="main_menu_faq_url" label="FAQ URL" />
            <LongTextInput source="main_menu_feedback_email" label="Feedback Email" />
            <RichTextInput source="about_html" label="About Us" />
          </FormTab>
        </TabbedForm>
      </Card>
    );
  }
}

CountryConfig.defaultProps = {
  undoable: true,
};

export default connect(
  undefined,
  {
    showNotification,
  },
)(CountryConfig);
