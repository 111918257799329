import React from 'react';

export default ({ record }) => {
  return (
    <span>
      Product Price
      {record ? `: ${record.items.sku} - ${record.items.item_name}` : ''}
    </span>
  );
};
