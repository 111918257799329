import React from 'react';

export default ({ record }) => {
  return (
    <span>
      Product
      {record ? `: ${record.item_name}` : ''}
    </span>
  );
};
