import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchStart, fetchEnd, crudCreate, crudUpdate, SaveButton, showNotification, startUndoable } from 'react-admin';
import initDataProvider from 'utils/dataProvider';
const dataProvider = initDataProvider();

class UploadSaveButtonView extends Component {
  state = {
    uploading: false,
  };

  save = values => {
    const {
      undoable,
      resource,
      record,
      basePath,
      startUndoable,
      crudCreate,
      dispatchCrudUpdate,
      redirect,
    } = this.props;

    if (record && record.id !== 'undefined' && record.id !== undefined) {
      if (undoable) {
        startUndoable(crudUpdate(resource, record.id, values, record, basePath, redirect));
      } else {
        dispatchCrudUpdate(resource, record.id, values, record, basePath, redirect);
      }
    } else {
      crudCreate(resource, values, basePath, redirect);
    }
  };

  handleClick = () => {
    const { resource, source, handleSubmit, fetchStart, fetchEnd, showNotification } = this.props;

    return handleSubmit(values => {
      var dataSource = [];
      for (var i = 0; i < source.length; i++) {
        let value = values['q' + source[i]];
        let field = source[i];
        if (value !== null && value !== undefined) {
          dataSource.push({
            field: field,
            value: value,
          });
        }
      }

      if (dataSource.length === 0) {
        this.save(values);
        return;
      }

      fetchStart();
      this.setState({ uploading: true });
      dataProvider('UPLOAD_MANY_IMG', resource, { dataSource: dataSource, values: values })
        .then(({ data }) => {
          this.setState({ uploading: false });
          data.map((d, index) => (values[dataSource[index].field] = d.img_url));

          return this.save({ ...values });
        })
        .catch(error => {
          this.setState({ uploading: false });
          showNotification(error.message, 'warning');
        })
        .finally(fetchEnd);
    });
  };

  render() {
    const {
      handleSubmitWithRedirect,
      crudCreate,
      dispatchCrudUpdate,
      startUndoable,
      fetchStart,
      fetchEnd,
      showNotification,
      source,
      resource,
      saving,
      undoable,
      ...props
    } = this.props;

    return (
      <SaveButton
        saving={saving || (this.state.uploading ? { redirect: props.redirect } : false)}
        handleSubmitWithRedirect={this.handleClick}
        {...props}
      />
    );
  }
}

UploadSaveButtonView.defaultProps = {
  undoable: true,
};

export default connect(
  undefined,
  {
    crudCreate,
    dispatchCrudUpdate: crudUpdate,
    showNotification,
    fetchStart,
    fetchEnd,
    startUndoable,
  },
)(UploadSaveButtonView);
