import React from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import ContentCreate from '@material-ui/icons/Create';
import { Link, Button } from 'react-admin';
// import { linkToRecord } from 'ra-core';

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

const ReferenceEditButton = ({
  reference = '',
  qssource = '',
  qskey = '',
  target = '',
  basePath = '',
  label = 'ra.action.edit',
  record = {},
  icon = <ContentCreate />,
  ...rest
}) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: `/${reference}/${record.id}`,
        search: `?${qskey}=${record[target]}`,
      }}
      label={label}
      onClick={stopPropagation}
      {...rest}
    >
      {icon}
    </Button>
  );
};

ReferenceEditButton.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  icon: PropTypes.element,
};

const enhance = shouldUpdate(
  (props, nextProps) =>
    props.translate !== nextProps.translate ||
    (props.record && nextProps.record && props.record.id !== nextProps.record.id) ||
    props.basePath !== nextProps.basePath ||
    (props.record == null && nextProps.record != null),
);

export default enhance(ReferenceEditButton);
