import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  Create,
  Edit,
  EditButton,
  SimpleForm,
  ImageInput,
  LongTextInput,
  NumberInput,
  required,
} from 'react-admin';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import { ListFilter, ListActions } from './ListActions';
import Title from './Title';
import Picture from 'components/Picture';
import PictureNote from 'components/PictureNote';
import UploadToolbar from 'components/UploadToolbar';

export const CartPromotionIcon = createSvgIcon(
  <React.Fragment>
    <path d="M20 6h-2.18c.11-.31.18-.65.18-1 0-1.66-1.34-3-3-3-1.05 0-1.96.54-2.5 1.35l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM9 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm11 15H4v-2h16v2zm0-5H4V8h5.08L7 10.83 8.62 12 11 8.76l1-1.36 1 1.36L15.38 12 17 10.83 14.92 8H20v6z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </React.Fragment>,
  'CardGiftcard',
);

export const CartPromotionList = props => (
  <List filters={<ListFilter />} actions={<ListActions />} title="Cart Promotions" {...props}>
    <Datagrid>
      <TextField source="id" label="No" />
      <ImageField source="img_url" label="Image" />
      <TextField source="product_code" label="Product Code" />
      <TextField source="product_name" label="Product Name" />
      <TextField source="party_sales_worth" label="Party Sales" />
      <TextField source="gift_value" label="Gift Value" />
      <EditButton />
    </Datagrid>
  </List>
);

export const CartPromotionCreate = props => (
  <Create title={'New CartPromotion'} {...props}>
    <SimpleForm redirect="list" toolbar={<UploadToolbar source={['img_url']} />}>
      <ImageInput source="qimg_url" label="New Image" accept="image/jpeg,image/png" validate={required()}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <PictureNote />
      <LongTextInput source="product_code" validate={required()} />
      <LongTextInput source="product_name" validate={required()} />
      <NumberInput source="party_sales_worth" validate={required()} />
      <NumberInput source="gift_value" label="Gift Value" validate={required()} />
    </SimpleForm>
  </Create>
);

export const CartPromotionEdit = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm toolbar={<UploadToolbar source={['img_url']} />}>
      <Picture field="img_url" />
      <ImageInput source="qimg_url" label="New Image" accept="image/jpeg,image/png">
        <ImageField source="src" title="title" />
      </ImageInput>
      <PictureNote />
      <LongTextInput source="product_code" validate={required()} />
      <LongTextInput source="product_name" validate={required()} />
      <NumberInput source="party_sales_worth" validate={required()} />
      <NumberInput source="gift_value" label="Gift Value" validate={required()} />
    </SimpleForm>
  </Edit>
);
