import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ImageField,
  BooleanField,
  Create,
  Edit,
  SimpleForm,
  EditButton,
  ReferenceInput,
  SelectInput,
  NumberInput,
  BooleanInput,
  ImageInput,
} from 'react-admin';
import { parse } from 'qs';
import { withStyles } from '@material-ui/core/styles';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import { ListFilter, ListActions } from './ListActions';
import Title from './Title';
import Picture from 'components/Picture';
import PictureNote from 'components/PictureNote';
import UploadToolbar from 'components/UploadToolbar';
export const ItemStackingIcon = createSvgIcon(
  <React.Fragment>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M2 21h19v-3H2v3zM20 8H3c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h17c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zM2 3v3h19V3H2z" />
  </React.Fragment>,
  'ViewDay',
);

const CustomProductName = ({ record }) => (
  <span>
    <b>{record.sku}</b> - {record.item_name}
  </span>
);

export const ItemStackingList = props => (
  <List
    filters={<ListFilter />}
    actions={<ListActions />}
    bulkActionButtons={false}
    title="Product Stackings"
    {...props}
  >
    <Datagrid>
      <TextField source="id" label="No" />
      <ReferenceField reference="items" source="item_id" label="Product Name" allowEmpty={true}>
        <TextField source="item_name" />
      </ReferenceField>
      <ImageField source="stacking_img_url" title="Image" label="Stacking Image" />
      <BooleanField source="is_stackable_above" />
      <BooleanField source="is_stackable_below" />
      <BooleanField source="is_same_width_only" />
      <TextField source="unit_w" />
      <TextField source="unit_h" />
      <TextField source="section_no" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ItemStackingCreate = props => {
  const query = parse(props.location.search, { ignoreQueryPrefix: true });
  let redirect = 'list',
    item_id = '';
  if (query.qs) {
    item_id = parseInt(query.qs, 10);
    redirect = `/items/${item_id}/1`;
  }
  return (
    <Create title={'New ItemStacking'} {...props}>
      <SimpleForm redirect={redirect} toolbar={<UploadToolbar source={['stacking_img_url']} />}>
        <ReferenceInput defaultValue={item_id} source="item_id" reference="items" label="Product Name" perPage={-1}>
          <SelectInput optionText={<CustomProductName />} />
        </ReferenceInput>
        <ImageInput source="qstacking_img_url" label="New Item Image" accept="image/jpeg,image/png">
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput source="is_stackable_above" />
        <BooleanInput source="is_stackable_below" />
        <BooleanInput source="is_same_width_only" />
        <NumberInput source="unit_w" />
        <NumberInput source="unit_h" />
        <NumberInput source="section_no" />
        <PictureNote />
      </SimpleForm>
    </Create>
  );
};

export const ItemStackingEdit = withStyles({ inlineBlock: { display: 'inline-flex', marginRight: '1rem' } })(
  ({ classes, ...props }) => {
    const query = parse(props.location.search, { ignoreQueryPrefix: true });
    let redirect = 'list',
      item_id = '';
    if (query.qs) {
      item_id = parseInt(query.qs, 10);
      redirect = `/items/${item_id}/1`;
    }
    return (
      <Edit title={<Title />} {...props}>
        <SimpleForm redirect={redirect} toolbar={<UploadToolbar source={['stacking_img_url']} />}>
          <ReferenceInput defaultValue={item_id} source="item_id" reference="items" label="Product Name" perPage={-1}>
            <SelectInput optionText={<CustomProductName />} />
          </ReferenceInput>
          <Picture field="stacking_img_url" />
          <ImageInput source="qstacking_img_url" label="New Item Image" accept="image/jpeg,image/png">
            <ImageField source="src" title="title" />
          </ImageInput>
          <BooleanInput source="is_stackable_above" />
          <BooleanInput source="is_stackable_below" />
          <BooleanInput source="is_same_width_only" />
          <NumberInput source="unit_w" />
          <NumberInput source="unit_h" />
          <NumberInput source="section_no" />
        </SimpleForm>
      </Edit>
    );
  },
);
