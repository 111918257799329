import React from 'react';
import { List, Datagrid, TextField, Create, Edit, SimpleForm } from 'react-admin';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import { ListFilter, ListActions } from './ListActions';
import Title from './Title';

export const ItemTypeIcon = createSvgIcon(
  <React.Fragment>
    <path d="M4 11h5V5H4v6zm0 7h5v-6H4v6zm6 0h5v-6h-5v6zm6 0h5v-6h-5v6zm-6-7h5V5h-5v6zm6-6v6h5V5h-5z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </React.Fragment>,
  'ViewModule',
);

export const ItemTypeList = props => (
  <List filters={<ListFilter />} actions={<ListActions />} title="Product Types" bulkActionButtons={false} {...props}>
    <Datagrid>
      <TextField source="id" label="No" />
      <TextField source="item_type_name" label="Name" />
      <TextField source="description" label="Description" />
    </Datagrid>
  </List>
);

export const ItemTypeCreate = props => (
  <Create title={'New ItemType'} {...props}>
    <SimpleForm />
  </Create>
);

export const ItemTypeEdit = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm />
  </Edit>
);
